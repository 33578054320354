import * as logger from 'loglevel';
import axios from './axios';
import ENV from './__env';

const validateLoginResponse = userResponse => {
  const validation = { isValid: true, message: '' };
  if (!userResponse) {
    validation.isValid = false;
    return {
      isValid: false,
      data: userResponse,
      message: 'INTEGRATION__LOGIN: WRONG USER OR PASSWORD'
    };
  }
  return validation;
};

const getLogin = (identifier, password) =>
  new Promise((resolve, reject) => {
    if (!identifier || !password) {
      const MESSAGE = `INTEGRATION__LOGIN: **username** or **password** not especified.`;
      logger.error(MESSAGE);
      reject(MESSAGE);
    }
    if (!ENV || !ENV.login || !ENV.login.method || !ENV.login.endPoint) {
      const MESSAGE = `INTEGRATION__LOGIN: **ENV.login** configuration issues.`;
      logger.error(MESSAGE);
      reject(MESSAGE);
    }
    axios({
      method: ENV.login.method,
      url: `${ENV.login.endPoint}`,
      data: {
        identifier,
        password
      }
    })
      .then(response => {
        const validation = validateLoginResponse(response.data);
        if (validation.isValid) {
          logger.info('INTEGRATION__LOGIN: Successful request', response.data);
          resolve(response.data);
        } else {
          resolve(false);
          logger.error(validation.message, validation.data);
          reject(response.data);
        }
      })
      .catch(err => {
        logger.error('INTEGRATION__LOGIN:', err);
        reject(err);
      });
  });

export default getLogin;
