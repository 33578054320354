import { apiUrl } from '../helpers/enviroment';

const ENV = {
  login: {
    endPoint: `${apiUrl}/auth/local`,
    method: 'POST'
  },
  last: {
    endPoint: `${apiUrl}/custom/tests/last`,
    method: 'GET'
  },
  all: {
    endPoint: `${apiUrl}/custom/tests/all`,
    method: 'GET'
  },
  findOne: {
    endPoint: `${apiUrl}/tests`,
    method: 'GET'
  }
};

export default ENV;
