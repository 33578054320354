const api = ({ method, url, headers, data }) =>
  new Promise((resolve, rejetc) => {
    headers = headers || {};
    if (typeof data === 'object') {
      data = JSON.stringify(data);
      if (!Object.hasOwnProperty(headers, 'Content-Type'))
        headers['Content-Type'] = 'application/json';
    }
    const xhr = new XMLHttpRequest();
    xhr.open(method, url, true);
    Object.keys(headers).map(h => {
      xhr.setRequestHeader(h, headers[h]);
    });
    xhr.onloadend = response => {
      let resp = {};
      if (response.currentTarget && xhr.responseText) resp = { data: xhr.responseText };
      try {
        resp = { data: JSON.parse(xhr.responseText) };
      } catch (e) {}
      resolve(resp);
    };
    xhr.onerror = error => {
      rejetc(error);
    };
    xhr.onabort = error => {
      rejetc(error);
    };
    if (data) xhr.send(data);
    else xhr.send();
  });

export default api;
