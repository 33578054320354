import React from 'react';
import { navigate, Link } from 'gatsby';
import { Container, Row, Col, Card, Form, Button, Navbar, Alert, Spinner } from 'react-bootstrap';
import { handleLogin, isLoggedIn } from '../services/auth';

class Login extends React.Component {
  state = {
    username: ``,
    password: ``,
    errorMessage: ``,
    isLogin: false
  };

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleSubmit = () => {
    this.setState({ isLogin: true }, () =>
      handleLogin(this.state)
        .then(() => {
          navigate(`/`);
        })
        .catch(err => {
          this.setState({
            errorMessage: err.message
          });
        })
        .finally(() => this.setState({ isLogin: false }))
    );
  };

  closeAlert = () => {
    this.setState({
      errorMessage: ``
    });
  };

  render() {
    if (isLoggedIn()) {
      navigate(`/`);
    }
    const { errorMessage, isLogin } = this.state;
    return (
      <>
        <Navbar bg="dark" variant="dark" expand="md">
          <Link to="/" className="nav-brand text-light">
            {'TEST-DASH'}
          </Link>
        </Navbar>
        <Container className="mt-5">
          <Row>
            <Col>
              <Card bg="dark" text="light">
                <Card.Body>
                  <h1>Log in</h1>
                  <hr style={{ width: '100%', color: 'light' }} />

                  <Form>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>username</Form.Label>
                      <Form.Control
                        type="text"
                        name="username"
                        placeholder="Enter username"
                        onChange={event => this.handleUpdate(event)}
                      />
                      <Form.Text className="text-muted">
                        {" We'll never share your username with anyone else."}
                      </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        name="password"
                        onChange={event => this.handleUpdate(event)}
                      />
                    </Form.Group>

                    <Button variant="primary" onClick={this.handleSubmit}>
                      {isLogin && <Spinner animation="border" variant="light" size="sm" />}
                      login
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {errorMessage !== '' && (
            <Row>
              <Col>
                <Alert variant="danger" onClose={this.closeAlert} dismissible className="mt-2">
                  <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                  <p>{errorMessage}</p>
                </Alert>
              </Col>
            </Row>
          )}
        </Container>
      </>
    );
  }
}

export default Login;
