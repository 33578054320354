import getLogin from './user';
import { isBrowser } from '../helpers/browser';

export const getUser = () =>
  isBrowser() && window.localStorage.getItem(`front10-jest-admin-dashboard-user`)
    ? window.localStorage.getItem(`front10-jest-admin-dashboard-user`)
    : undefined;

const setUser = user => {
  if (!user) localStorage.removeItem(`front10-jest-admin-dashboard-user`);
  else localStorage.setItem(`front10-jest-admin-dashboard-user`, user);
};

export const getUserInfo = () =>
  isBrowser() && window.localStorage.getItem(`front10-jest-admin-dashboard-user-info`)
    ? JSON.parse(window.localStorage.getItem(`front10-jest-admin-dashboard-user-info`))
    : undefined;

const setUserInfo = user => {
  if (!user) localStorage.removeItem(`front10-jest-admin-dashboard-user-info`);
  else localStorage.setItem(`front10-jest-admin-dashboard-user-info`, JSON.stringify(user));
};

// export const handleLogin = ({ username, password }) => {
//   return new Promise((resolve, reject) => {
//     if (!isBrowser()) {
//       reject(new Error('INTEGRATION__LOGIN: Error in LocaleStorage'));
//     } else {
//       getLogin(username, password)
//         .then(user => {
//           if (user.jwt) {
//             setUser(user.jwt);
//             setUserInfo(user.user);
//             resolve(user.jwt);
//           }
//         })
//         .catch(err => {
//           reject(new Error(`INTEGRATION__LOGIN: ${err}`));
//         });
//     }
//   });
// };

export const handleLogin = ({ username, password }) => {
  return new Promise((resolve, reject) => {
    if (!isBrowser()) {
      reject(new Error('INTEGRATION__LOGIN: Error in LocaleStorage'));
    } else {
      getLogin(username, password)
        .then(user => {
          if (user.jwt) {
            setUser(user.jwt);
            setUserInfo(user.user);
            resolve(user.jwt);
          } else reject(new Error(user.message));
        })
        .catch(err => {
          reject(new Error(err.message));
        });
    }
  });
};

export const isLoggedIn = () => {
  if (!isBrowser()) return false;
  const user = getUser();
  if (user) return true;
  return false;
};

export const logout = () => {
  if (!isBrowser()) return;
  setUser();
  if (isBrowser()) window.location.reload();
};
